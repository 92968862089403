import Home from './assets/videos/home.mp4'

export const cards = [
  {
    id: 'bored-apes',
    image: '/banners/bored-apes-yacht-club-banner.png',
  },
  {
    id: 'ted-lasso',
    image:
      'https://nameh-dhiman.github.io/Apple-TV-Clone-Project.github.io/Images/Tedd%20Lasso.jpg',
  },
  {
    id: 'sky-is-everywhere',
    image:
      'https://9to5mac.com/wp-content/uploads/sites/6/2022/02/sky-is-everywhere-how-to-watch.jpg?quality=82&strip=all',
  },
  {
    id: 'servant',
    image: 'https://flxt.tmsimg.com/assets/p17586756_b_h8_af.jpg',
  },
  {
    id: 'dear',
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/93/50/f4/9350f4f6-4c50-f6a5-6976-446a5333b265/U0ZfVFZBX1dXX0RlYXItUzItQ29taW5nX1Nvb24tQnJpY2subHNy.lsr/1478x832fe.webp',
  },
  {
    id: 'the-morning-show',
    image:
      'https://www.apple.com/tv-pr/articles/2022/01/global-hit-series-the-morning-show-starring-and-executive-produced-by-jennifer-aniston-and-reese-witherspoon-renewed-for-season-three/images/big-image/big-image-01/011021_The_Morning_Show_Renewed_Season_Three_Big_Image_01_big_image_post.jpg.large.jpg',
  },
]

export const images = [
  {
    img1: 'https://is3-ssl.mzstatic.com/image/thumb/Features122/v4/30/8c/1b/308c1bdb-c1c7-fa4f-33d1-08065907d59f/e0e6538f-f6d0-467f-816e-077180a1e041.png/1320x495sr.webp',
  },
  {
    img2: 'https://is2-ssl.mzstatic.com/image/thumb/Features112/v4/d9/f5/d6/d9f5d634-80af-c981-53ef-8f539a6f5f72/a33fda54-395d-4345-a1f1-0f737e7b72b5.png/1320x495sr.webp',
  },
  {
    img3: 'https://is2-ssl.mzstatic.com/image/thumb/Features122/v4/02/df/97/02df9769-b6da-99cc-22ca-10b2f07f1a7f/bd117faa-2f63-48b0-9428-a6bb93b36cd5.png/1320x495sr.webp',
  },
  {
    img4: 'https://is2-ssl.mzstatic.com/image/thumb/Features1…c9-912e-4adf-88a0-6ca00a56becc.png/999x374sr.webp',
  },
]

const comingSoon = [
  {
    id: 1,
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/d8/b8/bf/d8b8bff6-e7b0-5be4-9930-a779aea319e3/U0YtVFZBLVdXLVRoZV9Qcm9ibGVtX0pvbl9TdGV3YXJ0Lmxzcg.lsr/1478x832fe.webp',
    info: 'New Episodes Premiere March 3',
    video: Home,
  },
  {
    id: 2,
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/93/50/f4/9350f4f6-4c50-f6a5-6976-446a5333b265/U0ZfVFZBX1dXX0RlYXItUzItQ29taW5nX1Nvb24tQnJpY2subHNy.lsr/1478x832fe.webp',
    info: 'New Season Premiere March 3',
    video: Home,
  },
  {
    id: 3,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features125/v4/db/34/3b/db343b95-7cd0-2001-0282-771fff181727/U0YtVFZBLVdXLUNlbnRyYWxfUGFyay1TMkEtTGF1bmNoLmxzcg.lsr/1478x832fe.webp',
    info: 'New Episodes Premiere March 4',
    video: Home,
  },
  {
    id: 4,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features126/v4/49/c4/8b/49c48b53-8b6f-773f-be76-26ec9ab48152/U0YtVFZBLVdXLUxhc3RfRGF5c19vZl9QdG9sZW15X0dyZXktQ1NfQnJpY2subHNy.lsr/1478x832fe.webp',
    info: 'New Series Premieres March 11',
    video: Home,
  },
  {
    id: 5,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features116/v4/96/0d/8e/960d8ef8-5773-9678-7738-c74c9d763853/U0YtVFZBLVdXLVRoZV9Tbm9vcHlfU2hvdy1TMkEtQ1NfQnJpY2subHNy.lsr/1478x832fe.webp',
    info: 'New Season Premiere March 11',
    video: Home,
  },
  {
    id: 6,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features116/v4/32/1e/39/321e395c-2b34-6f5a-3768-764252769471/U0YtVFZBLVdXLVdlY3Jhc2hlZC1DUy1Ccmlja19ub0J1Zy5sc3I.lsr/1478x832fe.webp',
    info: 'New Series Premiere March 18',
    video: Home,
  },
  {
    id: 7,
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Features126/v4/e0/04/56/e0045660-db0b-37da-bde5-91a431d28574/U0YtVFZBLVdXLVBhY2hpbmtvLUVhcmx5X0NTLmxzcg.lsr/1478x832fe.webp',
    info: 'New Series Premiere March 25',
    video: Home,
  },
  {
    id: 8,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features116/v4/a2/a4/0d/a2a40dbb-06be-1791-d241-019657d17469/U0YtVFZBLVdXLVNsb3dfSG9yc2VzLUNTLUJyaWNrLW5vQnVnLmxzcg.lsr/1478x832fe.webp',
    info: 'New Series Premiere April 1',
    video: Home,
  },
  {
    id: 9,
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/Features116/v4/25/ee/d1/25eed172-b1de-32c4-ea17-be19f53cf21e/U0YtVFZBLVdXLVNoaW5pbmdfR2lybHMtQ1MubHNy.lsr/1478x832fe.webp',
    info: 'New Series Premiere April 29',
    video: Home,
  },
  {
    id: 10,
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/7e/e4/05/7ee40559-a590-1c90-9057-d9e14c76af6d/U0YtVFZBLVdXLUx1Y2stQ1MtQnJpY2stbm9CdWcubHNy.lsr/1478x832fe.webp',
    info: 'New Film Premiere August 5',
    video: Home,
  },
]

const tales = [
  {
    id: 1,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/6tJakyTEtS0vrgdc9POZpg/738x416.webp',
    video: Home,
  },
  {
    id: 2,
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/yXhlXOFkS9yJ1kMmOhfIbg/738x416.webp',
    video: Home,
  },
  {
    id: 3,
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/h8oGFN-jdPeHY8j3EM_WnQ/738x416.webp',
    video: Home,
  },
  {
    id: 4,
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Cof8vC9vaCH-GYX6JltrQA/738x416.webp',
    video: Home,
  },
  {
    id: 5,
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/EjmCDwq97jEEe9RuKY7aNw/738x416.webp',
    video: Home,
  },
  {
    id: 6,
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/78-I7VenST4ztZYfwMf6AQ/738x416.webp',
  },
  {
    id: 7,
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/NHLksWLSZpTcIw36-n7vWA/738x416.webp',
    video: Home,
  },
]

const mysteries = [
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/dbEKo7rp5RGNNDrCbA7AUQ/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/vB48H4eBprLJ-WdCBTA6cw/738x416.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/mqP1mK0HzuFhIBXsd_Q_SA/738x416.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/RP47edx-hRW2rCun48Lx2w/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/FQV5QO8Xa5gbRlB8jXX9rw/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/kVTKGnVvCdFlB1sZeKa8YQ/738x416.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/L4WNJNV3nbmyAL6MQSeGuw/738x416.webp',
  },
]

const voice = [
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/zRCSBlp0LjwClRXsjyDNYQ/738x416.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/VXktJkcnADdcq8RjZVT_nw/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/3iAbZQ4pznOpDRenkvOhNA/738x416.webp',
    video: Home,
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/rZ34KsuVSjdutVP-0HO95A/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/D1SCGzM1ESBh-SIkHwcvUQ/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/_ODFuW8DJbcFSHL_yhHuVA/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Ru8FEVLXR20CLzgnt9UXOQ/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/cyRvgYnPlaVn3rN2SDdW1Q/738x416.webp',
  },
]

const extraOrd = [
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/qth_eNoJqMWbjMUyn16jpA/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Becoming You',
    desc: 'Olivia Colman narratesthis docuseries about how kids grow in their first five years.',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/qAk-30MspnyIG9hVKJ1qKQ/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Dear...',
    desc: "Profiles of 10 game-changing icons and those whose lives they've inspired.",
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/uBU112xIP48X2tW5EbpGGQ/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: "The Me You Can't See",
    desc: 'Oprah and Prince Harry guide honest discussions about mental health.',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/1hOcr3gchtyju9nzwvFcAg/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Home',
    desc: "Discover the stories and ideas behind the world's most imaginative dwellings.",
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/RMn-3IiFm6XMOJZT3tP8fA/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Visible Out On Television',
    desc: 'Explore the history of the LGBTQ movement through the lens of TV.',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/jER5ceAuuLVFije5LmLVLw/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Greatness Code',
    desc: 'Seven iconic athletes reveal the pivotal career moment they touched greatness.',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/6MFkEmAhzwoAZfThq0xWHA/1478x832.webp',
    genre: 'TALK SHOW',
    title: 'The Oprah Conversation',
    desc: 'Intimate discussions with newsmakers, thought leaders, and masters of craft.',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/QYnchKIdrKG_b0lkikPUSw/1478x832.webp',
    genre: 'DOCUMENTARY',
    title: 'Dads',
    desc: "Bryce Dallas Howard's doc is a joyful, star- studded look at being dad today.",
  },
]

const creatures = [
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/owNSUO3F109RC75YDPMY7A/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/2lfdtvo9bvduvVLrbcASow/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/6f5y-DYx9UvvcJ-gpqAufw/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/FEcg1yXIW7vfftSbhFKsQg/738x416.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/AS1GpWWwwGwHxrXD7Sny5A/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/_Kq-GgPdDxxqWtSZ9GbHug/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/cuTV_0CMBiqg34YfIP2s0Q/738x416.webp',
  },
]

const jumboSliderData = [
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Features116/v4/66/8a/8a/668a8a2d-c65d-7165-29e1-257115a37645/RVNXLVRWQS1XVy1Pc2NhcnMtVGhlX1RyYWdlZHlfb2ZfTWFjYmV0aC5wbmc.png/1320x495sr.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Features116/v4/5c/69/1e/5c691e3d-f436-651c-27b1-80273cc31899/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZV9Pc2NhcnMtQ09EQS5wbmc.png/1320x495sr.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/Features126/v4/38/ea/d8/38ead8ac-0cb6-a41f-fcc4-25b0e3e92ff1/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZV9RdW90ZV9TZXZlcmFuY2UucG5n.png/1320x495sr.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Features116/v4/9d/ba/f8/9dbaf8be-aa79-eece-b465-497c7d9fd1fa/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZV9RdW90ZS1UaGVfQWZ0ZXJwYXJ0eS5wbmc.png/1320x495sr.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/85/3c/c0/853cc067-fb76-5346-2411-705fcc8203de/RVNXLVRWQS1XVy1Td2FuX1NvbmctTkFBQ1BfQXdhcmQucG5n.png/1320x495sr.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Features126/v4/fa/bf/a4/fabfa44e-2077-90ff-dfa2-23fc70e1bfa1/RVNXLVRWQS1XVy1UTVMtU0FHX0F3YXJkLnBuZw.png/1320x495sr.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/9b/70/94/9b709448-8024-7e0b-57f8-d551cdbd9c01/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZS1UZWRfTGFzc29fUzItbGFuZz1lbl91cy5wbmc.png/1320x495sr.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/7f/50/36/7f5036da-fcf2-21b0-5c22-e1a137459a90/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZV9RdW90ZS1GaW5jaC1sYW5nPWVuX3VzLnBuZw.png/1320x495sr.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Features115/v4/b0/e3/6e/b0e36ee0-6bd7-107d-a106-f06c28c5607e/RVNXLVRWQS1XVy1Ucm9waHlfQ2FzZV9RdW90ZS1Gb3JfQWxsX01hbmtpbmQtbGFuZz1lbl91cy5wbmc.png/1320x495sr.webp',
  },
]

const dramaSeries = [
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/78-I7VenST4ztZYfwMf6AQ/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/kVTKGnVvCdFlB1sZeKa8YQ/738x416.webp ',
  },
  {
    image:
      ' https://is3-ssl.mzstatic.com/image/thumb/AWDRdQz0nepFpnsUNiTDuw/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/6tJakyTEtS0vrgdc9POZpg/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/NHLksWLSZpTcIw36-n7vWA/738x416.webp ',
  },

  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/qYEYYvFXN2O4zEuCcBSbjw/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/UPoQbHoPte_TFJXEvKIIsw/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/VXktJkcnADdcq8RjZVT_nw/738x416.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/SEsiWD2cTDShiJqUtRPAxg/738x416.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/vB48H4eBprLJ-WdCBTA6cw/738x416.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Iz8wdsRXYRysnRRCY0yMrA/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/AmQ1cNEzc1DMKH25B5J9aQ/738x416.webp ',
  },

  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Cof8vC9vaCH-GYX6JltrQA/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/HSPjQEW5mDqwAbMbbhfNOQ/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/yXhlXOFkS9yJ1kMmOhfIbg/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/h8oGFN-jdPeHY8j3EM_WnQ/738x416.webp',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/EjmCDwq97jEEe9RuKY7aNw/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/_ODFuW8DJbcFSHL_yhHuVA/738x416.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/L4WNJNV3nbmyAL6MQSeGuw/738x416.webp',
  },
]

const severWN = [
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/Features126/v4/ba/79/6b/ba796be7-d1ce-6233-a033-15ba8cd4a93e/U0YtVFZBLVdXLVNldmVyYW5jZV9MYXVuY2gtQlJUT18xLmxzcg.lsr/1478x832fe.webp',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/54/81/33/54813342-edf4-dfd7-097b-2eca75be553f/U0YtVFZBLVdXLVNldmVyYW5jZV9MYXVuY2gtQlJUT18yLmxzcg.lsr/1478x832fe.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Features116/v4/4e/b1/a2/4eb1a21f-940b-1108-d7e0-5718aeac7d1c/U0YtVFZBLVdXLVNldmVyYW5jZV9MYXVuY2gtQlJUT18zLmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/5f/c0/f3/5fc0f3e0-cb94-a101-b341-0867af7afa42/U0YtVFZBLVdXLVNldmVyYW5jZV9MYXVuY2gtQlJUT180Lmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Features116/v4/2e/52/f7/2e52f7a7-d75f-5347-ec2f-8442dd7d09be/U0YtVFZBLVdXLVNldmVyYW5jZV9MYXVuY2gtQlJUT181Lmxzcg.lsr/1478x832fe.webp ',
  },
]

const featurefilms = [
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/cyRvgYnPlaVn3rN2SDdW1Q/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/xwiVfxV1l5Tfy6-1uQ25_Q/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/oANBVngpEJDvHRhdyozySA/738x416.webp ',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/Epzt9WNlE21aE-FNCoVj2w/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/D1SCGzM1ESBh-SIkHwcvUQ/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/fS85BR5uGUlTFLXLAWAMsg/738x416.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/s0GHu23qor3EapMRUh-4iw/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/KdlcNbBUOtf7sUXH5z9N8A/738x416.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/iNMKAaCahjd4PPdXo0D5iA/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/3UIt7-edNvHDrXR_FkZd6A/738x416.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/K-nsm8mEVNAAnevMTyya7g/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/97liMx1hwAc7jcp5zDXp8Q/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/CwpcQl754x9lwddZ9TXqmQ/738x416.webp ',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/fwOVLEMoZhr-wkppMR12XA/738x416.webp',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/6fu6YRITQhblYxAGDfPR3A/738x416.webp',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/2kwDdUMkGYVufGBF3g1hdA/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/QYnchKIdrKG_b0lkikPUSw/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/jRVuOiECbTmzx3HEVkbreA/738x416.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/jvpbfkNAZaLIN3DZJUDX8Q/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Ru8FEVLXR20CLzgnt9UXOQ/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/iDoRIny_hEKEOksP68uLYQ/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/CurGU6004wvloeDxunoxyA/738x416.webp ',
  },
  {
    image:
      ' https://is2-ssl.mzstatic.com/image/thumb/jbuAq3KUPjh27XucTemVxg/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/8593WFOGVTTA1ncIGrL37g/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/k-4X4dDwJrtXlsgr1VI_0w/738x416.webp ',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/zRCSBlp0LjwClRXsjyDNYQ/738x416.webp',
  },
]

const comSeries = [
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/I6UAhe-RRHaTB3hCQdQXCQ/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/dbEKo7rp5RGNNDrCbA7AUQ/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/rZ34KsuVSjdutVP-0HO95A/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/_VWBeTzPLn6LeyUjlTaSiQ/738x416.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/le6mxqLNaXH47MHIH7stzw/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/vWs6dt5xDhG_DjIoc-89tA/738x416.webp ',
  },
  {
    image:
      ' https://is5-ssl.mzstatic.com/image/thumb/y-GzfKyC0y6x5Dd8254GpQ/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/3iAbZQ4pznOpDRenkvOhNA/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/D8bOyY8jg5hymGMxt1LSZg/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/eGnsZyr_h0ivXT0r4jbaew/738x416.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/jdSxJnPbX9kKPTfMKUHvww/738x416.webp',
  },
]

const amazPlanet = [
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features114/v4/98/5f/f4/985ff45d-4afb-a34f-1e88-39011b2ca443/U0YtVFZBLVdXLUZhY3R1YWxfTXVsdGlUaXRsZV9Ccmlja18xLmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/Features124/v4/84/21/5d/84215d8f-e47b-edaa-e7b0-f94a147ac467/U0YtVFZBLVdXLUZhY3R1YWxfTXVsdGlUaXRsZV9Ccmlja18yLmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features124/v4/7e/f1/dd/7ef1ddeb-62fb-cfb7-a531-d1e63f5d0fdf/U0YtVFZBLVdXLUZhY3R1YWxfTXVsdGlUaXRsZV9Ccmlja18zLmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/90/09/0b/90090b35-00a5-3543-b3e6-84609ece1ed1/U0YtVFZBLVdXLUZhY3R1YWxfTXVsdGlUaXRsZV9Ccmlja180LXAzLmxzcg.lsr/1478x832fe.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/Features124/v4/9c/78/15/9c78158d-404f-c2df-d309-beea3df71702/U0YtVFZBLVdXLUZhY3R1YWxfTXVsdGlUaXRsZV9FbnRpdGxlZF9Ccmlja181Lmxzcg.lsr/998x562fe.webp ',
  },
]

const nonFi = [
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/a99v9iRDYcIGof0CvxUUoA/738x416.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/L79If4rL5GAF2ffGc7jBRg/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/izP6zMmzs-m3VCjcOvM-DQ/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/RFbs1Kj9CJgtWAYKwmOEig/738x416.webp ',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/VlRkLTAvxgEml_ZmCcmP1Q/738x416.webp',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/xW-YFC38wO80XQewW5Mn7A/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/uBU112xIP48X2tW5EbpGGQ/738x416.webp ',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/KS0pkmrVEdO4uYZA5CpRZA/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/6kPVBOcFxH5eWRVp9Vy9BA/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/qth_eNoJqMWbjMUyn16jpA/738x416.webp ',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/04JyAIWtM1q-61kfjf6BsQ/738x416.webp',
  },
  {
    image:
      ' https://is2-ssl.mzstatic.com/image/thumb/nMny0tR8aTkNRR3FHWQcAw/738x416.webp',
  },
  {
    image:
      ' https://is3-ssl.mzstatic.com/image/thumb/6MFkEmAhzwoAZfThq0xWHA/738x416.webp',
  },
  {
    image:
      ' https://is2-ssl.mzstatic.com/image/thumb/jER5ceAuuLVFije5LmLVLw/738x416.webp',
  },
  {
    image:
      ' https://is2-ssl.mzstatic.com/image/thumb/qAk-30MspnyIG9hVKJ1qKQ/738x416.webp',
  },
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/1hOcr3gchtyju9nzwvFcAg/738x416.webp',
  },
  {
    image:
      ' https://is5-ssl.mzstatic.com/image/thumb/KL014j7cDAvmHE0IXLfC5Q/738x416.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/IpgXC7UdAxwRnIwu9nuZBg/738x416.webp ',
  },
  {
    image:
      ' https://is5-ssl.mzstatic.com/image/thumb/RMn-3IiFm6XMOJZT3tP8fA/738x416.webp',
  },
]

const kidsFam = [
  {
    image:
      ' https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/95/66/71/95667123-58b3-212e-06b7-4c6fe10ee787/U0YtVFZBLVVTQS1BVFZQbHVzX0tpZHMtQnJpY2tfMS5sc3I.lsr/998x562fe.webp',
  },
  {
    image:
      ' https://is2-ssl.mzstatic.com/image/thumb/Features116/v4/40/e4/3a/40e43a6a-fa61-07b3-a77a-11c622f938fd/U0YtVFZBLVVTQS1BVFZQbHVzX0tpZHMtQnJpY2tfMi5sc3I.lsr/998x562fe.webp',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/e7/7f/6d/e77f6d2a-f6c5-8c95-0d06-89c7e6edb080/U0YtVFZBLVVTQS1BVFZQbHVzX0tpZHMtQnJpY2tfMy5sc3I.lsr/998x562fe.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/Features116/v4/f9/81/8f/f9818faf-8b61-a76a-3398-acf53238cacf/U0YtVFZBLVVTQS1BVFZQbHVzX0tpZHMtQnJpY2tfNC5sc3I.lsr/998x562fe.webp',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Features126/v4/e2/14/9a/e2149a62-3520-2f41-c465-04790607811c/U0YtVFZBLVVTQS1BVFZQbHVzX0tpZHMtQnJpY2tfNS5sc3I.lsr/998x562fe.webp',
  },
]

const SeasonData = [
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/_bb2PnhfUkQghivGL5TWaw/1478x832.webp',
    episode: 'EPISODE 1',
    title: 'Good News About Hell',
    description:
      "Assistant District Atterney Andy Barker is assigned as the lead prosecutor in the case of his son's murder classmate",
    time: '45 min Apr 24,2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/ysLP9oqE4AMGvF9o1NUAPA/1478x832.webp',
    episode: 'EPISODE 2',
    title: 'Evertyhing is cool',
    description: 'When new eveidence is discovered,Andy is taken off the case.',
    time: '45 min Apr 24,2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/jpZB7C7S-PB3pirMZ5JjGA/1478x832.webp',
    episode: 'EPISODE 3',
    title: 'In Perpeuity',
    description:
      'Andy admits a family secert to Laurie before it becomes public',
    time: '52 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/vcA_30XmwAeIzgMQHvS7XA/1478x832.webp',
    episode: 'EPISODE 4',
    title: 'Damage Control',
    description:
      'Andy takes matters into his own hands.Laurie reexamines the life she knows',
    time: '48 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/mzJBIallKQ2MK4_wjspGjA/1478x832.webp',
    episode: 'EPISODE 5',
    title: 'visitors',
    description:
      "Andy visits his father, and learns some troubling information from Jacob's friends Derek and Sarah",
    time: '48 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/Xa-zFgX6BBxwUpFa_QUDnA/1478x832.webp',
    episode: 'EPISODE 6',
    title: 'Wishful Thinking',
    description:
      "Andy and klein explore two last-ditch efforts to prove Jacob's innocence",
    time: '46 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/kNp-QOoh8BzdPT8TLBcYTQ/1478x832.webp',
    episode: 'EPISODE 7',
    title: 'Job',
    description: 'As the trial begins jacob fate hangs in the balace',
    time: '46 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/FU0p12VhEvrTy3UslZk9vA/1478x832.webp',
    episode: 'EPISODE 8',
    title: 'After',
    description:
      'Series finale.the trial comes to an end but with unexpected consequenses ',
    time: '46 min Apr 24, 2020',
    tv: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-tv' viewBox='0 0 16 16' ><path d='M2.5 13.5A.5.5 0 0 1 3 13h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zM13.991 3l.024.001a1.46 1.46 0 0 1 .538.143.757.757 0 0 1 .302.254c.067.1.145.277.145.602v5.991l-.001.024a1.464 1.464 0 0 1-.143.538.758.758 0 0 1-.254.302c-.1.067-.277.145-.602.145H2.009l-.024-.001a1.464 1.464 0 0 1-.538-.143.758.758 0 0 1-.302-.254C1.078 10.502 1 10.325 1 10V4.009l.001-.024a1.46 1.46 0 0 1 .143-.538.758.758 0 0 1 .254-.302C1.498 3.078 1.675 3 2 3h11.991zM14 2H2C0 2 0 4 0 4v6c0 2 2 2 2 2h12c2 0 2-2 2-2V4c0-2-2-2-2-2z'/></svg>",
    cc: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-cc' viewBox='0 0 16 16' ><path d='M3.708 7.755c0-1.111.488-1.753 1.319-1.753.681 0 1.138.47 1.186 1.107H7.36V7c-.052-1.186-1.024-2-2.342-2C3.414 5 2.5 6.05 2.5 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114H6.213c-.048.615-.496 1.05-1.186 1.05-.84 0-1.319-.62-1.319-1.727v-.743zm6.14 0c0-1.111.488-1.753 1.318-1.753.682 0 1.139.47 1.187 1.107H13.5V7c-.053-1.186-1.024-2-2.342-2C9.554 5 8.64 6.05 8.64 7.751v.747c0 1.7.905 2.73 2.518 2.73 1.314 0 2.285-.792 2.342-1.939v-.114h-1.147c-.048.615-.497 1.05-1.187 1.05-.839 0-1.318-.62-1.318-1.727v-.743z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Ad: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-ad' viewBox='0 0 16 16' ><path d='m3.7 11 .47-1.542h2.004L6.644 11h1.261L5.901 5.001H4.513L2.5 11h1.2zm1.503-4.852.734 2.426H4.416l.734-2.426h.053zm4.759.128c-1.059 0-1.753.765-1.753 2.043v.695c0 1.279.685 2.043 1.74 2.043.677 0 1.222-.33 1.367-.804h.057V11h1.138V4.685h-1.16v2.36h-.053c-.18-.475-.68-.77-1.336-.77zm.387.923c.58 0 1.002.44 1.002 1.138v.602c0 .76-.396 1.2-.984 1.2-.598 0-.972-.449-.972-1.248v-.453c0-.795.37-1.24.954-1.24z'/><path d='M14 3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h12zM2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2z'/></svg>",
    Sd: "<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='currentColor' class='bi bi-badge-sd' viewBox='0 0 16 16' ><path fill-rule='evenodd' d='M15 4a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4ZM0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm5.077 7.114c-1.524 0-2.263-.8-2.315-1.749h1.147c.079.466.527.809 1.234.809.739 0 1.13-.339 1.13-.83 0-.418-.3-.634-.923-.779l-.927-.215c-.932-.21-1.52-.747-1.52-1.657 0-1.098.918-1.815 2.24-1.815 1.371 0 2.162.77 2.22 1.692H6.238c-.075-.43-.466-.76-1.103-.76-.655 0-1.046.338-1.046.804 0 .36.294.598.821.712l.932.216c.971.22 1.613.685 1.613 1.691 0 1.117-.857 1.881-2.378 1.881ZM8.307 11V5.001h2.19c1.823 0 2.684 1.09 2.684 2.984 0 1.908-.874 3.015-2.685 3.015H8.308Zm2.031-5.032h-.844v4.06h.844c1.116 0 1.622-.667 1.622-2.02 0-1.354-.51-2.04-1.622-2.04Z'/></svg>",
  },
]

const CastData = [
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/hiflAFJjngZrTMqEGq5uog/492x492ve.webp',
    name: 'Chirs Evans',
    role: 'Andy Barber',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/3YRoQrc6hyE2IJhx3qsvtw/492x492ve.webp',
    name: 'Michelle Dockery',
    role: 'Laurie Barber',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/FxJi3UnEMkUN5esSY5Tn4A/492x492ve.webp',
    name: 'Jaeden Martell',
    role: 'Jacob Barber',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/el8L3CNh_it68l3NwRYEYg/492x492ve.webp',
    name: 'Cherry jones',
    role: 'Joanna klein',
  },
  {
    image:
      'https://is1-ssl.mzstatic.com/image/thumb/MMhPj6KAtomcJ5AQcTztaA/492x492ve.webp',
    name: 'Pablo Schreiber',
    role: 'Neal Loguidice',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/HMmekTQAts_l8MDpGmZhFw/492x492ve.webp',
    name: 'Betty Gabriel',
    role: 'Pam Duffy',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/DdvI0WEDl8HGNOxIuNvcKA/492x492ve.webp',
    name: 'Sakina jaffrey',
    role: 'Lynn Canavan',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/0fUqfV6L-egYglhR0ZBPQw/492x492ve.webp',
    name: 'Mark Bomback',
    role: 'Executive producer',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/rrOnTxiBYGEQZEJqNdGxdg/492x492ve.webp',
    name: 'Morten Tyidum',
    role: 'Executive producer',
  },
  {
    image:
      'https://is5-ssl.mzstatic.com/image/thumb/912KxFyPIe4ppx3MdElAYA/492x492ve.webp',
    name: 'Rosalle Swedin',
    role: 'Executive Producer',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/3WR8e1QwWF-cnIjLsiwVvA/492x492bb.webp',
    name: 'Chirs Evans',
    role: 'Executive Producer',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/0fUqfV6L-egYglhR0ZBPQw/492x492ve.webp',
    name: 'Mark Bombaxk',
    role: 'creator',
  },
]

const RelatedData = [
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/I6UAhe-RRHaTB3hCQdQXCQ/738x416.webp',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/dbEKo7rp5RGNNDrCbA7AUQ/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/rZ34KsuVSjdutVP-0HO95A/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/_VWBeTzPLn6LeyUjlTaSiQ/738x416.webp ',
  },
  {
    image:
      'https://is2-ssl.mzstatic.com/image/thumb/le6mxqLNaXH47MHIH7stzw/738x416.webp ',
  },
  {
    image:
      'https://is4-ssl.mzstatic.com/image/thumb/vWs6dt5xDhG_DjIoc-89tA/738x416.webp ',
  },
  {
    image:
      ' https://is5-ssl.mzstatic.com/image/thumb/y-GzfKyC0y6x5Dd8254GpQ/738x416.webp',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/3iAbZQ4pznOpDRenkvOhNA/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/D8bOyY8jg5hymGMxt1LSZg/738x416.webp ',
  },
  {
    image:
      'https://is3-ssl.mzstatic.com/image/thumb/eGnsZyr_h0ivXT0r4jbaew/738x416.webp ',
  },
  {
    image:
      ' https://is4-ssl.mzstatic.com/image/thumb/jdSxJnPbX9kKPTfMKUHvww/738x416.webp',
  },
]

export {
  comingSoon,
  tales,
  mysteries,
  voice,
  extraOrd,
  creatures,
  jumboSliderData,
  dramaSeries,
  severWN,
  featurefilms,
  comSeries,
  amazPlanet,
  nonFi,
  kidsFam,
  RelatedData,
  CastData,
  SeasonData,
}
