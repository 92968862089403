import React, { useState, useEffect } from 'react'
import {
  Box,
  IconButton,
  useBreakpointValue,
  Stack,
  Heading,
  Container,
  Text,
  Button,
} from '@chakra-ui/react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import Slider from 'react-slick'
import { useNavigate } from 'react-router-dom'

import { cards } from '../../db'

// Settings for the slider
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
}

export function Banner() {
  const navigate = useNavigate()
  const initialAccessToken =
    window?.__wpal?.client?.clientApi?.accessToken || ''
  const [accessToken, setAccessToken] = useState(initialAccessToken)
  const [slider, setSlider] = useState('')
  const [isWatchNow, setIsWatchNow] = useState(false)

  console.log('accessToken', accessToken)

  const top = useBreakpointValue({ base: '90%', md: '50%' })
  const side = useBreakpointValue({ base: '30%', md: '40px' })

  React.useEffect(() => {
    function handleAccessTokenUpdate(e) {
      console.log('handleAccessTokenUpdate')
      setAccessToken(e.detail.accessToken)
    }

    window.addEventListener(
      'walletpal:accessToken:acquired',
      handleAccessTokenUpdate
    )

    return () => {
      window.removeEventListener(
        'walletpal:accessToken:acquired',
        handleAccessTokenUpdate
      )
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        if (!accessToken || !window?.__wpal?.client?.clientApi?.getUserInfo) {
          return
        }
        const response = await window?.__wpal?.client?.clientApi?.getUserInfo()
        setIsWatchNow(true)
      } catch (err) {}
    })()
  }, [accessToken])

  return (
    <Box
      position={'relative'}
      height={'650px'}
      width={'full'}
      overflow={'hidden'}
    >
      {/* CSS files for react-slick */}
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      {/* Left Icon */}
      <IconButton
        aria-label="left-arrow"
        variant="ghost"
        position="absolute"
        left={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickPrev()}
      >
        <BiLeftArrowAlt size="40px" />
      </IconButton>
      {/* Right Icon */}
      <IconButton
        aria-label="right-arrow"
        variant="ghost"
        position="absolute"
        right={side}
        top={top}
        transform={'translate(0%, -50%)'}
        zIndex={2}
        onClick={() => slider?.slickNext()}
      >
        <BiRightArrowAlt size="40px" />
      </IconButton>
      {/* Slider */}
      <Slider {...settings} ref={(slider) => setSlider(slider)}>
        {cards.map((card) => (
          <Box
            key={card.id}
            height={'2xl'}
            position="relative"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundImage={`url(${card.image})`}
          >
            <Box
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              boxShadow="0 0 300px rgba(0,0,0,0.9) inset"
            ></Box>
            <Box
              position="absolute"
              bottom="0"
              left="0"
              width="1000px"
              height="700px"
              borderRadius="50%"
              transform={'translate(-50%, 45%)'}
              backdropFilter="blur(2px) brightness(98%)"
            ></Box>
            <Container
              height="100%"
              maxWidth="100%"
              position="relative"
              shadow="inner"
            >
              <Stack
                spacing={6}
                width="400px"
                position="absolute"
                color="white"
                left="0"
                bottom="0"
                textAlign="left"
                paddingLeft="5%"
                paddingBottom="5%"
                zIndex="10"
              >
                <Heading fontSize="3xl">
                  {getBannerTitle(card.id, { isWatchNow })}
                </Heading>
                <Text fontSize={{ base: 'md', lg: 'lg' }} color="white">
                  {getBannerText(card.id, { isWatchNow })}
                </Text>
                {isWatchNow ? (
                  <Button
                    width="218px"
                    height="48px"
                    color="#021342"
                    px="20px"
                    py="12px"
                    fontSize="16px"
                    lineHeight="24px"
                    onClick={() => navigate('/details/1')}
                  >
                    {getBannerButtonText(card.id, { isWatchNow })}
                  </Button>
                ) : (
                  <div
                    className="wpal-connect"
                    data-client-id="demoApp"
                    data-redirect-uri="https://demo.walletpal.xyz"
                  ></div>
                )}
              </Stack>
            </Container>
          </Box>
        ))}
      </Slider>
    </Box>
  )
}

function getBannerTitle(cardId, { isWatchNow }) {
  if (isWatchNow) {
    if (cardId === 'bored-apes') {
      return 'The Bored Ape Yacht Club Movie'
    } else {
      return 'Watch this amazing Production'
    }
  }

  return 'The Home of Video+ Originals'
}

function getBannerText(cardId, { isWatchNow }) {
  if (isWatchNow) {
    if (cardId === 'bored-apes') {
      return "Take a behind-the-scenes look at history's biggest NFT craze. Watch the new film."
    } else {
      return 'New chances and challenges. Watch now!'
    }
  }

  return 'Connect to enjoy star-studded, award-winning series, films, and more'
}

function getBannerButtonText(cardId, { isWatchNow }) {
  if (isWatchNow) {
    if (cardId === 'bored-apes') {
      return 'Go to Movie'
    } else {
      return 'Go to Show'
    }
  }

  return 'Connect with WalletPal'
}
